import { Navbar, Nav } from 'react-bootstrap'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/esm/Container'
import NavbarBrand from 'react-bootstrap/esm/NavbarBrand'
import NavLink from 'react-bootstrap/esm/NavLink'
import './App.css'
import { useEffect } from 'react'

function AppLayout() {
  return (
    <>
      <Navbar bg='light' sticky='top'>
        <Container>
          <NavbarBrand href='/about'>CaseWorkSolutions</NavbarBrand>
          <Nav className='me-auto'>
            <NavLink href='/about'>About</NavLink>
            <NavLink href='/projects'>Projects</NavLink>
            <NavLink href='/contact'>Contact</NavLink>
          </Nav>
        </Container>
      </Navbar>
      <Outlet />
    </>
  )
}

export default AppLayout;
