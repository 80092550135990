import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import AppLayout from './AppLayout'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  [
    {
      path: '*',
      element: <AppLayout />,
      children: [
        {
          path: 'About',
          lazy: async () => {
            const { About } = await import('./About')
            return { element: <About /> }
          }
        },
        {
          path: 'Projects',
          lazy: async () => {
            const { Projects } = await import('./Projects')
            return { element: <Projects /> }
          }
        },
        {
          path: '*',
          element: <Navigate to='/About' />
        }
      ]
    }
  ]
)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
